<template>
  <div class="boss">
    <div class="logoImg">
      <img src="../assets/img/logo.png" alt="" />
    </div>
    <div class="center">
      <div class="qipao">
        <img src="../assets/img/qipao.png" alt="" />
      </div>
      <div class="text">
        <div class="rotate">
          <img :src="user.headimgurl" alt="" />
          <div class="flex">
            <p class="name">{{ user.nickname }}</p>
            <p class="num" v-if="user.adoptCount != 0">
              已领养数量：{{ user.adoptCount }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <img src="../assets/img/add.png" alt="" @click="addFriend" />
      <p>通过链接进游戏，即可添加TA为好友哦~</p>
    </div>
    <van-popup v-model="show">
      <div class="bossBox">
        <p class="verify">手机号绑定验证</p>
        <div>
          <van-cell-group>
            <van-field
              v-model="phone"
              clearable
              :border="false"
              type="number"
              maxlength="11"
              placeholder="请填写手机号"
              left-icon="http://static.yhaocang.com/img/pages/pc/phone.png"
            />
          </van-cell-group>
        </div>
        <div class="yanzheng">
          <van-cell-group class="cell">
            <van-field
              v-model="code"
              :border="false"
              placeholder="验证码"
              left-icon="http://static.yhaocang.com/img/pages/pc/ma.png"
            />
          </van-cell-group>
          <div v-show="codeShow" class="song" @click="getCode">获取验证码</div>
          <div v-show="!codeShow" class="song">
            {{ yzmTitle }}
          </div>
        </div>
        <div class="sure" @click="isTrue">确定</div>
      </div>
    </van-popup>
    <p class="titile" v-show="titleShow">{{ msg }}</p>
    <t-captcha-js src="https://ssl.captcha.qq.com/TCaptcha.js"></t-captcha-js>
  </div>
</template>

<script>
import { sendSms, bindFriend, shareUse } from "../api/index";
export default {
  components: {
    "t-captcha-js": {
      render(createElement) {
        return createElement("script", {
          attrs: { type: "text/javascript", src: this.src },
        });
      },
      props: {
        src: { type: String, required: true },
      },
    },
  },
  data() {
    return {
      show: false,
      phone: "",
      msg: "", //提示
      code: "",
      yzmTitle: "",
      titleShow: false,
      codeShow: true, //图片验证
      user: "",
    };
  },
  created() {
    this.shareUser();
  },
  methods: {
    async shareUser() {
      const res = await shareUse({ uid: this.$route.query.uid });
      if (res.data.code != 200) return;
      this.user = res.data.data;
      console.log(this.user, "this.user");
    },
    addFriend() {
      console.log("添加好友");
      this.show = !this.show;
    },
    async isTrue() {
      console.log("确定");
      let data = {
        rid: this.$route.query.rid || "",
        uid: this.$route.query.uid,
        phone: this.phone,
        keyCode: this.code,
      };
      let res = await bindFriend(data);
      console.log(res, "resres");
      if (res.data.code != 200) {
        this.msg = res.data.message;
        console.log(this.msg, "this.msg");
        this.titleShow = true;
        setTimeout(() => {
          this.titleShow = false;
        }, 2000);
        return;
      }
      this.show = !this.show;
      this.$router.push({
        path:"/Skippage",
        query:{scene:'Home',pageName:"friend_list"}
      }) 
    },
    getCode() {
      if (!this.phone) {
        this.msg = "请输入正确的手机号码";
        console.log(this.msg, "this.msg");
        this.titleShow = true;
        setTimeout(() => {
          this.titleShow = false;
        }, 2000);
        return;
      }
      let reg = /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/;
      if (!reg.test(this.phone)) {
        this.msg = "请输入正确的手机号码";
        console.log(this.msg, "this.msg");
        this.titleShow = true;
        setTimeout(() => {
          this.titleShow = false;
        }, 2000);
        return;
      }
      this.showCodeImg();
    },
    showCodeImg() {
      // eslint-disable-next-line no-undef
      var captcha1 = new TencentCaptcha("2008794111", (coderes) => {
        console.log(coderes);
        const { randstr, ticket } = coderes;
        sendSms({ phone: this.phone, type1: 1, randstr, ticket }).then(
          (res) => {
            console.log(res);
            if (res.data.code == 200) {
              let time = 60;
              let timer = setInterval(() => {
                if (time == 0) {
                  clearInterval(timer);
                  this.codeShow = true;
                } else {
                  this.codeShow = false;
                  this.yzmTitle = time + "秒后重试";
                  time--;
                }
              }, 1000);
              this.msg = "获取验证码成功";
              console.log(this.msg, "this.msg");
              this.titleShow = true;
              setTimeout(() => {
                this.titleShow = false;
              }, 2000);
            } else {
              this.$toast(res.data.message);
            }
          }
        );
      });
      captcha1.show();
    },
  },
};
</script>

<style lang="scss" scoped>
.boss {
  width: 100vw;
  height: 100vh;
  background-image: url("../assets/img/live.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 100%;
}
.logoImg {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  img {
    width: 3.2rem;
    height: 2.7rem;
    object-fit: contain;
    margin-right: 0.5rem;
  }
}
.center {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  position: relative;
  margin-top: 2.6rem;
  .qipao {
    position: absolute;
    bottom: 5.8rem;
    left: 0.5rem;
    img {
      width: 5rem;
      height: auto;
      height: 100%;
    }
  }
  .text {
    width: 9rem;
    height: 7rem;
    background-image: url("../assets/img/xinfu.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 100%;
    margin-left: 0.43rem;
    .rotate {
      width: 100%;
      height: 6rem;
      display: flex;
      // justify-content: center;
      flex-direction: column;
      align-items: center;
      transform: rotate(-12deg);
      img {
        width: 1.7rem;
        height: 1.7rem;
        border-radius: 50%;
        object-fit: cover;
        margin-top: 1.68rem;
      }
      .flex {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 0.5rem;
        .name {
          font-size: 0.5rem;
          font-weight: bold;
          color: #8b2a00;
          text-align: center;
        }
        .num {
          font-size: 0.4rem;
          font-weight: 400;
          color: #8b2a00;
          margin-top: 0.15rem;
        }
      }
    }
  }
}
.bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.4rem;
  img {
    width: 5rem;
    height: auto;
  }
  p {
    font-size: 0.4rem;
    margin-top: 0.3rem;
    color: #000;
  }
}
.van-popup {
  border-radius: 0.15rem;
  overflow: hidden;
}
.bossBox {
  width: 8rem;
  height: 6.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    margin: 0;
    padding: 0;
  }
  .verify {
    font-size: 0.53rem;
    color: #333;
    font-weight: 400;
    padding: 0.8rem 0 0.25rem 0;
  }
  .yanzheng {
    width: 7rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .song {
      width: 2.76rem;
      height: 1.15rem;
      background: #43d227;
      border-radius: 0.1rem;
      margin-top: 0.2rem;
      font-size: 0.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fefefe;
      font-weight: 400;
    }
  }
  .sure {
    width: 4.6rem;
    height: 1.1rem;
    background: #43d227;
    font-size: 0.45rem;
    color: #fefefe;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    margin-top: 0.4rem;
    border-radius: 2rem;
  }
}
.van-cell-group {
  margin-top: 0.2rem;
  width: 7rem;
  .van-cell {
    height: 1.2rem;
    display: flex;
    align-items: center;
    background: #f2f2f2;
    border-radius: 0.15rem;
    overflow: hidden;
  }
  /deep/ .van-icon {
    font-size: 0.8rem;
    margin: 0 0.3rem 0 0.34rem;
  }

  /deep/ .van-field__body {
    font-size: 0.41rem;
  }
}
.cell {
  width: 4rem;
  /deep/ .van-icon {
    font-size: 0.65rem;
    margin: 0 0.3rem 0 0.41rem;
  }
}
.titile {
  font-size: 0.4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(#000, 0.4);
  padding: 0.2rem 0.5rem;
  color: #fff;
  border-radius: 0.2rem;
  z-index: 999999;
}
</style>
